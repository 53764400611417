import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
    loading: false,
    allCategoriesHistory: [],
    page: 1,
    pages: 1,
    pageSize: 10,
};
const defaultPath = "/store-categories";

export const fetchAllStoreCategoryHistory = createAsyncThunk(
    "fetch_all_storeCategory_history",
    async ({
        page = 1,
        pageSize = 10,
        search = "",
        store = "",
    }) => {
        try {
            const url = `${defaultPath}/all-history?page=${page}&pageSize=${pageSize}&search=${search}&store=${store}`;
            const { data } = await API.get(url);
            return data;
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
);

const storeCategoryHistorySlice = createSlice({
    name: "storeCategoryHistory",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAllStoreCategoryHistory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllStoreCategoryHistory.fulfilled, (state, action) => {
            state.loading = false
            if (action?.payload?.success) {
                state.allCategoriesHistory = action.payload.allCategoriesHistory;
                state.page = action.payload.page;
                state.pageSize = action.payload.pageSize;
                state.pages = action.payload.pages;
            }
        })
    }
})

export const storeCategoryHistoryReducer = storeCategoryHistorySlice.reducer