import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allGiftCards: [],
  giftCardDetails: {},
  allGiftCardsList: [],
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/giftcards";

export const fetchAllGiftCards = createAsyncThunk(
  "fetch_giftCards",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    name = "",
    active = "",
    priority = "",
  }) => {
    try {
      const url = `${defaultPath}/all-gift-cards?page=${page}&pageSize=${pageSize}&search=${search}&name=${name}&active=${active}&priority=${priority}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllGiftCardsList = createAsyncThunk(
  "fetch_giftCards_list",
  async ({ active = "" }) => {
    try {
      const { data } = await API.get(`${defaultPath}/gift-cards-list?active=${active}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createGiftCard = createAsyncThunk(
  "create_giftCard",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateGiftCard = createAsyncThunk(
  "update_giftCard",
  async ({ giftcardId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${giftcardId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateGiftCardPriority = createAsyncThunk(
  "update_giftCard_priority",
  async ({ giftCardId, priority }) => {
    try {
      const { data } = await API.patch(`${defaultPath}/update/priority/${giftCardId}`, {
        priority,
      });
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteGiftCard = createAsyncThunk(
  "deleteGiftCard",
  async (giftCardId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${giftCardId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchGiftCardDetails = createAsyncThunk(
  "fetch_giftCard_details",
  async (giftCardId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${giftCardId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const giftCardSlice = createSlice({
  name: "giftCards",
  initialState,
  extraReducers: (builder) => {
    // fetch all giftCard offer
    builder.addCase(fetchAllGiftCards.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllGiftCards.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCards = action.payload.allGiftCards;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    builder.addCase(fetchAllGiftCardsList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllGiftCardsList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardsList = action.payload.allGiftCards;

      }
    });
    // create gift card
    builder.addCase(createGiftCard.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createGiftCard.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"new gift card created successfully"} />);
      }
    });
    // delete  gift card
    builder.addCase(deleteGiftCard.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGiftCard.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"successfully deleted giftCard "} />);
      }
    });
    // fetch  gift card details
    builder.addCase(fetchGiftCardDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGiftCardDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.giftCardDetails = action.payload.giftCard;
      }
    });
    // update  gift card
    builder.addCase(updateGiftCard.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGiftCard.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"gift card updated successfully"} />);
      }
    });
    // update  gift card priority
    builder.addCase(updateGiftCardPriority.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGiftCardPriority.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"successfully updated giftcard priority"} />);
      }
    });
  },
});

export const giftCardReducer = giftCardSlice.reducer;
