import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allStoreReviews: [],
  reviewDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/reviews";

export const fetchAllStoreReviews = createAsyncThunk(
  "fetch_all_store_reviews",
  async ({ page = 0, pageSize = 10, search = "", store = "" }) => {
    try {
      const { data } = await API.get(
        `${defaultPath}/get-all-reviews?page=${page}&pageSize=${pageSize}&search=${search}&store=${store}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteStoreReview = createAsyncThunk(
  "delete_store_review",
  async (reviewId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${reviewId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const storeReviewSlice = createSlice({
  name: "storeReview",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllStoreReviews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStoreReviews.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStoreReviews = action.payload.allStoreReviews;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    builder.addCase(deleteStoreReview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteStoreReview.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStoreReviews = state.allStoreReviews.filter(
          (item) => item._id != action.payload.reviewId
        );
        toast.custom(<Success message={"review deleted successfully!"} />);
      }
    });
  },
});

export const storeReviewReducer = storeReviewSlice.reducer;

// export const getAllReviews = () => API.get("/reviews/all-reviews");
// export const filterReviews = async ({ page, keyword, store }) => {
//   const link = `/reviews/filter?page=${page}&keyword=${keyword}&store=${store}`;
//   const data = await API.post(link);
//   return data;
// };

// export const deleteStoreReview = (reviewId) =>
//   API.delete(`/reviews/${reviewId}`);
