import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Store } from "./redux/store";

createRoot(document.getElementById("root")).render(
  <Provider store={Store}>
    <Toaster
      position="top-right"
      gutter={3}
      toastOptions={{ duration: 4000 }}
    />
    <App />
  </Provider>
);

