import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
    loading: false,
    allPersonalInterests: [],
    personalInterestDetails: {},
    error: "",
    page: 1,
    pageSize: 10,
    pages: 1,
};

const defaultPath = "/personal-interest";

export const fetchAllPersonalInterests = createAsyncThunk(
    "all_personal_interests",
    async ({
        page = 1,
        pageSize = 10,
        search = "",
    }) => {
        try {
            const url = `${defaultPath}/all-personal-interests?page=${page}&pageSize=${pageSize}&search=${search}`;
            const { data } = await API.get(url);
            return data;
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
);

export const createPersonalInterest = createAsyncThunk(
    "create_personal_interest",
    async (payload) => {
        try {
            const { data } = await API.post(`${defaultPath}/create`, payload);
            return data;
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
);

export const updatePersonalInterest = createAsyncThunk(
    "update_personal_interest",
    async ({ id, payload }) => {
        try {
            const { data } = await API.put(`${defaultPath}/update/${id}`, payload)
            return data
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
)

const personalInterestSlice = createSlice({
    name: "personalInterest",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAllPersonalInterests.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllPersonalInterests.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                state.allPersonalInterests = action.payload.allPersonalInterests;
                state.page = action.payload.page;
                state.pageSize = action.payload.pageSize;
                state.pages = action.payload.pages;
            }
        })

        builder.addCase(createPersonalInterest.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createPersonalInterest.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                toast.custom(<Success message={"successfully create new personal interest"} />)
            }
        })

        builder.addCase(updatePersonalInterest.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updatePersonalInterest.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                state.allPersonalInterests = state.allPersonalInterests.map((item) => {
                    if (item._id === action.payload.personalInterest._id) {
                        item.active = action.payload.personalInterest.active
                        item.name = action.payload.personalInterest.name
                        return item
                    }
                    return item
                })
                toast.custom(<Success message={"successfully updated personal interest details"} />)
            }
        })
    }
})

export const personalInterestReducer = personalInterestSlice.reducer