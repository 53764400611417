import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allAffiliations: [],
  allAffiliationsList: [],
  affiliationDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/affiliations";

export const fetchAllAffiliations = createAsyncThunk(
  "fetch_all_affiliations",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/get-all-affiliations?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllAffiliationsList = createAsyncThunk(
  "fetch_all_affiliations_list",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/all-affiliation-list`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createAffiliation = createAsyncThunk(
  "create_affiliation",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateAffiliation = createAsyncThunk(
  "update_affiliation",
  async ({ affiliationId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${affiliationId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteAffiliation = createAsyncThunk(
  "delete_affiliation",
  async (affiliationId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/delete/${affiliationId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAffiliationDetails = createAsyncThunk(
  "fetch_affiliation_details",
  async (affiliationId) => {
    try {
      const { data } = await API.post(
        `${defaultPath}/details/${affiliationId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const affiliationSlice = createSlice({
  name: "affiliation",
  initialState,
  extraReducers: (builder) => {
    // fetch all affiliations
    builder.addCase(fetchAllAffiliations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAffiliations.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAffiliations = action.payload.allAffiliations;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // fetch all affiliations list
    builder.addCase(fetchAllAffiliationsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAffiliationsList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAffiliationsList = action.payload.allAffiliations;
      }
    });
    // create new affiliation
    builder.addCase(createAffiliation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAffiliation.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"create new affiliation successfully"} />
        );
      }
    });

    // fetch affiliation details
    builder.addCase(fetchAffiliationDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAffiliationDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.affiliationDetails = action.payload.affiliation;
      }
    });
    // update affiliation
    builder.addCase(updateAffiliation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAffiliation.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={" affiliation updated successfully"} />);
      }
    });
    // update affiliation
    builder.addCase(deleteAffiliation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAffiliation.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAffiliations.map((item) => {
          if (item._id == action.payload.affiliation._id) {
            item.active = action.payload.affiliation.active;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success
            message={`successfully ${action.payload.affiliation.active ? "restored" : "deleted"
              } affiliation`}
          />
        );
      }
    });
  },
});

export const affiliationReducer = affiliationSlice.reducer;

// export const getAllPartners = () => API.get("/partners/all-partners");
// export const getPartnerDetails = (partnerId) =>
//   API.post(`/partners/details/${partnerId}`);
// export const createPartners = (partnerData) =>
//   API.post("/partners/create", partnerData);
// export const updatePartners = (partnerData) =>
//   API.put(`/partners/update/${partnerData._id}`, partnerData);
// export const deletePartners = (partnerId) =>
//   API.patch(`/partners/delete-status/${partnerId}`);
// export const searchPartners = (keyword, page) =>
//   API.post(`/partners/filter/search?page=${page}&keyword=${keyword}`);
