class TokenService {
  getLocalRefreshToken() {
    const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
    return refreshToken;
  }

  getLocalAccessToken() {
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    return accessToken;
  }

  updateLocalRefreshToken(token) {
    let refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
    refreshToken = token;
    localStorage.setItem("refreshToken", JSON.stringify(admin));
  }
  updateLocalAccessToken(token) {
    const admin = JSON.parse(localStorage.getItem("accessToken"));
    accessToken = token;
    localStorage.setItem("accessToken", JSON.stringify(admin));
  }
  getAdmin() {
    return JSON.parse(localStorage.getItem("admin"));
  }
  removeAdmin() {
    localStorage.removeItem("admin");
  }
}

export default new TokenService();
