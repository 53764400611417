import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allGiftCardOffers: [],
  giftCardOfferDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/giftcards/offers";

export const fetchAllGiftCardOffers = createAsyncThunk(
  "fetch_giftCard_offers",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    name = "",
    giftCard = "",
    expiry = "",
  }) => {
    try {
      const url = `${defaultPath}/all-offers?page=${page}&pageSize=${pageSize}&search=${search}&name=${name}&giftCard=${giftCard}&expiry=${expiry}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createGiftCardOffer = createAsyncThunk(
  "create_giftCard_offer",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateGiftCardOffer = createAsyncThunk(
  "update_giftCard_offer",
  async ({ offerId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${offerId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteGiftCardOffer = createAsyncThunk(
  "delete_giftCard_Offer",
  async (offerId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${offerId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchGiftCardOfferDetails = createAsyncThunk(
  "fetch_giftCard_offer_details",
  async (offerId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${offerId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const giftCardOfferSlice = createSlice({
  name: "giftCardOffers",
  initialState,
  extraReducers: (builder) => {
    // fetch all giftCard offer
    builder.addCase(fetchAllGiftCardOffers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllGiftCardOffers.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardOffers = action.payload.allGiftCardOffers;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // create gift card offer
    builder.addCase(createGiftCardOffer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createGiftCardOffer.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"new giftcard offer created successfully"} />);
      }
    });
    // delete  gift card offer
    builder.addCase(deleteGiftCardOffer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGiftCardOffer.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardOffers = state.allGiftCardOffers.map((item) => {
          if (item._id === action.payload.giftCardOffer._id) {
            item.active = action.payload.giftCardOffer.active
            return item
          }
          return item
        })
        toast.custom(<Success message={"giftcard offer active status updated successfully"} />);
      }
    });
    // fetch  gift card offer details
    builder.addCase(fetchGiftCardOfferDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGiftCardOfferDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.giftCardOfferDetails = action.payload.giftCardOffer;
      }
    });
    // update  gift card offer
    builder.addCase(updateGiftCardOffer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGiftCardOffer.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"giftcard offer updated successfully"} />);
      }
    });
  },
});

export const giftCardOfferReducer = giftCardOfferSlice.reducer;
