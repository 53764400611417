import axios from "axios";
import TokenService from "./token";
import AuthService from "../api/auth";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: "https://admin-api.indiancashback.com/api",
  // baseURL: "http://localhost:4000/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    // console.log({error});
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/admin/auth/sign-in" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const { data } = await instance.post("/admin/auth/refresh-token", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          if (data.token_hack) {
            AuthService.logout();
            return;
          }
          const { accessToken, refreshToken } = data;
          TokenService.updateLocalAccessToken(accessToken);
          TokenService.updateLocalRefreshToken(refreshToken);

          return instance(originalConfig);
        } catch (_error) {
          if (_error?.response?.data?.errors === "Invalid refresh token") {
            localStorage.clear();
            window.location.href = "/login";
          }
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
