import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
    loading: false,
    allTestimonials: [],
    testimonialDetails: {},
    error: "",
    page: 1,
    pageSize: 10,
    pages: 1,
};

const defaultPath = "/testimonials";

export const fetchAllTestimonials = createAsyncThunk(
    "all_testimonials",
    async ({
        page = 1,
        pageSize = 10,
        search = "",
    }) => {
        try {
            const url = `${defaultPath}/all-testimonials?page=${page}&pageSize=${pageSize}&search=${search}`;
            const { data } = await API.get(url);
            return data;
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
);

export const createTestimonials = createAsyncThunk(
    "create_testimonials",
    async (payload) => {
        try {
            const { data } = await API.post(`${defaultPath}/create`, payload);
            return data;
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
);

export const updateTestimonials = createAsyncThunk(
    "update_testimonial",
    async ({ testimonialId, payload }) => {
        try {
            const { data } = await API.put(`${defaultPath}/update/${testimonialId}`, payload)
            return data
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
)

export const fetchTestimonialDetails = createAsyncThunk(
    "get_testimonial",
    async (id) => {
        try {
            const { data } = await API.post(`${defaultPath}/details/${id}`)
            return data
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
)

export const updateTestimonialActiveStatus = createAsyncThunk(
    "update_testimonial_active_status",
    async (id) => {
        try {
            const { data } = await API.patch(`${defaultPath}/update-status/${id}`)
            return data
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
            }
            toast.custom(
                <Danger message={error?.response?.data?.errors || error.message} />
            );
        }
    }
)

const testimonialSlice = createSlice({
    name: "testimonials",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAllTestimonials.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllTestimonials.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                state.allTestimonials = action.payload.allTestimonials;
                state.page = action.payload.page;
                state.pageSize = action.payload.pageSize;
                state.pages = action.payload.pages;
            }
        })

        builder.addCase(createTestimonials.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createTestimonials.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                toast.custom(<Success message={"successfully create new testimonials"} />)
            }
        })

        builder.addCase(fetchTestimonialDetails.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTestimonialDetails.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                state.testimonialDetails = action.payload.testimonial
            }
        })

        builder.addCase(updateTestimonials.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateTestimonials.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                toast.custom(<Success message={"successfully updated testimonial details"} />)
            }
        })

        builder.addCase(updateTestimonialActiveStatus.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateTestimonialActiveStatus.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.success) {
                state.allTestimonials = state.allTestimonials.map((item) => {
                    if (item._id === action.payload.testimonialId) {
                        item.active = !item.active
                        return item
                    }
                    return item
                })
                toast.custom(<Success message={"successfully updated testimonial active status details"} />)
            }
        })
    }
})

export const testimonialReducer = testimonialSlice.reducer