import TokenService from "./token";
import API from './api'

class AuthService {
    logout() {
       const {_id} = TokenService.getAdmin()
        return API
            .post("/admin/auth/logout",{adminId:_id})
            .then(response => {
                TokenService.removeAdmin();
                return response.data;
            }).then(error => console.log({ error }));
    }

}

export default new AuthService();
