import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allClicks: [],
  clickDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/clicks";

export const fetchAllClicks = createAsyncThunk(
  "fetchAllClicks",
  async ({
    page = 1,
    search = "",
    pageSize = 10,
    affiliation = "",
    store = "",
    status = "",
    userType = "",
    fromDate = "",
    toDate = "",
  }) => {
    try {
      const url = `${defaultPath}/get-all-clicks?page=${page}&pageSize=${pageSize}&search=${search}&store=${store}&status=${status}&affiliation=${affiliation}&fromDate=${fromDate}&toDate=${toDate}&userType=${userType}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchClickDetails = createAsyncThunk(
  "fetchClickDetails",
  async (id) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${id}`);

      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const clickSlice = createSlice({
  name: "clicks",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllClicks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllClicks.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allClicks = action.payload.allClicks;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });

    builder.addCase(fetchClickDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchClickDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.clickDetails = action.payload.click;
      }
    });
  },
});

export const clickReducer = clickSlice.reducer;
