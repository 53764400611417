import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allIcbGiftCardOrders: [],
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/icb-giftcard";

export const fetchAllIcbGiftCardOrders = createAsyncThunk(
  "fetchAllIcbGiftCardOrders",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    name = "",
    active = "",
    priority = "",
  }) => {
    try {
      const url = `${defaultPath}/all-orders?page=${page}&pageSize=${pageSize}&search=${search}&name=${name}&active=${active}&priority=${priority}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const icbGiftCardSlice = createSlice({
  name: "icbGiftCard",
  initialState,
  extraReducers: (builder) => {
    // fetch all giftCard offer
    builder.addCase(fetchAllIcbGiftCardOrders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllIcbGiftCardOrders.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allIcbGiftCardOrders = action.payload.allIcbGiftCardOrders;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
  },
});

export const icbGiftCardOrderReducer = icbGiftCardSlice.reducer;
