import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allPaymentRequests: [],
  paymentsDetails: {},
  error: "",
  page: 1,
  pageSize: 10,
  pages: 1,
};

const defaultPath = "/payments/request";

export const fetchAllPaymentRequests = createAsyncThunk(
  "fetchAllPaymentRequests",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    startDate = "",
    endDate = new Date(),
    status = "",
    store = "",
    affiliation = "",
    amount = -1,
  }) => {
    try {
      const url = `${defaultPath}/all-requests?page=${page}&pageSize=${pageSize}&search=${search}&startDate=${startDate}&endDate=${endDate}&status=${status}&store=${store}&affiliation=${affiliation}&amount=${amount}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchPaymentRequestDetails = createAsyncThunk(
  "fetchPaymentRequestDetails",
  async (id) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const approvePaymentRequest = createAsyncThunk(
  "approvePaymentStatus",
  async (id) => {
    try {
      const { data } = await API.patch(`${defaultPath}/approve/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const rejectPaymentRequest = createAsyncThunk(
  "rejectPaymentStatus",
  async (id) => {
    try {
      const { data } = await API.patch(`${defaultPath}/reject/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const paymentRequestSlice = createSlice({
  name: "paymentRequest",
  initialState,
  extraReducers: (builder) => {
    // fetch all payments
    builder.addCase(fetchAllPaymentRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllPaymentRequests.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allPaymentRequests = action.payload.allPaymentRequests;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });

    // approve payment request
    builder.addCase(approvePaymentRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(approvePaymentRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allPaymentRequests = state.allPaymentRequests.map((item)=>{
          if(item._id === action.payload.paymentRequest._id){
            item.status = action.payload.paymentRequest.status
            return item
          }
          return item
        })
        toast.custom(
          <Success message={"successfully approved the payment request"} />
        );
      }
    });
    // reject payment request
    builder.addCase(rejectPaymentRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rejectPaymentRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allPaymentRequests = state.allPaymentRequests.map((item)=>{
          if(item._id === action.payload.paymentRequest._id){
            item.status = action.payload.paymentRequest.status
            return item
          }
          return item
        })
        toast.custom(
          <Success message={"successfully rejected the payment request"} />
        );
      }
    });
  },
});

export const paymentRequestReducer = paymentRequestSlice.reducer;
