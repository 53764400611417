import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allSubCategory: [],
  allSubCategoryList: [],
  subCategoryDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/categories/sub";
export const fetchAllSubCategories = createAsyncThunk(
  "fetch_all_sub_category",
  async ({ page = 1, pageSize = 10, search = "", category = "" }) => {
    try {
      const url = `${defaultPath}/all-categories?page=${page}&pageSize=${pageSize}&search=${search}&category=${category}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllSubCategoriesList = createAsyncThunk(
  "fetch_all_sub_category_list",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/all-categories-list`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createSubCategory = createAsyncThunk(
  "create_sub_category",
  async (payload) => {
    try {
      const { data } = await API.post(
        `${defaultPath}/create/${payload.category}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateSubCategory = createAsyncThunk(
  "update_sub_category",
  async ({ categoryId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${categoryId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchSubCategoryDetails = createAsyncThunk(
  "fetch_sub_category_details",
  async (categoryId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${categoryId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  "delete_sub_category",
  async (categoryId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${categoryId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  extraReducers: (builder) => {
    // fetch all categories
    builder.addCase(fetchAllSubCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSubCategories.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allSubCategory = action.payload.allSubCategories;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // fetch all categories list
    builder.addCase(fetchAllSubCategoriesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSubCategoriesList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allSubCategoryList = action.payload.allSubCategories;
      }
    });
    // create new category
    builder.addCase(createSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"created new sub category successfully"} />
        );
      }
    });

    // fetch category details
    builder.addCase(fetchSubCategoryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubCategoryDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.subCategoryDetails = action.payload.subCategory;
      }
    });
    // update category
    builder.addCase(updateSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"sub category updated successfully"} />);
      }
    });
    // delete category
    builder.addCase(deleteSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        // state.allSubCategory.map((item) => {
        //   if (item._id === action.payload.subcategory._id) {
        //     item.active = action.payload.subcategory.active;
        //     return item;
        //   }
        //   return item;
        // });

        state.allSubCategory = state.allSubCategory.filter(
          (item) => item._id !== action.payload.subcategory._id
        );
        toast.custom(
          <Success message={"sub category deleted successfully!"} />
        );
      }
    });
  },
});

export const subCategoryReducer = subCategorySlice.reducer;

// export const getAllSubCategories = () =>
//   API.get("/categories/sub/all-categories");
// export const CreateSubCategory = (categoryData) =>
//   API.post(`/categories/sub/create/${categoryData.categoryId}`, categoryData);
// export const subCategoryDelete = (categoryId) =>
//   API.delete(`/categories/sub/delete/${categoryId}`);
// export const filteredSubCategories = (data) =>
//   API.post(`/categories/sub/filtered/${data}`);
// export const searchSubCategories = (keyword, page) =>
//   API.post(`/categories/sub/filter/search?page=${page}&keyword=${keyword}`);
// export const updateSubCategory = (data, categoryId) =>
//   API.put(`/categories/sub/update/${categoryId}`, data);
// export const getSubCategoryDetails = (categoryId) =>
//   API.post(`/categories/sub/details/${categoryId}`);
