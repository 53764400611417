import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openModal: false,
    image: null,
    content: null
};

const previewModalSlice = createSlice({
    initialState,
    name: "previewModal",
    reducers: {
        handleOpenModal: (state, action) => {
            state.openModal = true;
            if (action?.payload?.image) {
                state.image = action.payload?.image
                state.content = null
            }
            if (action?.payload?.content) {
                state.content = action.payload?.content
                state.image = null
            }
        },
        handleCloseModal: (state) => {
            state.openModal = false;
            state.image = null
            state.content = null
        }
    },
});

export const { handleOpenModal, handleCloseModal } = previewModalSlice.actions;

export default previewModalSlice.reducer;
