import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allTrendingStores: [],
  trendingStoreDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};

const defaultPath = "/store/trending";

export const fetchAllTrendingStores = createAsyncThunk(
  "fetch_all_trending_stores",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/all-trending-stores?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const createTrendingStore = createAsyncThunk(
  "create_trending_store",
  async (storeId) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, { storeId });
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateTrendingStorePriority = createAsyncThunk(
  "update_trending_store_priority",
  async ({ storeId, priority }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update-priority/${storeId}`,
        { priority }
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteTrendingStore = createAsyncThunk(
  "delete_trending_store",
  async (storeId) => {
    try {
      const { data } = await API.patch(`${defaultPath}/delete/${storeId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const trendingStoreSlice = createSlice({
  name: "trendingStore",
  initialState,
  extraReducers: (builder) => {
    // fetch all trending store
    builder.addCase(fetchAllTrendingStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTrendingStores.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTrendingStores = action.payload.allStores;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // create new trending store
    builder.addCase(createTrendingStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTrendingStore.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully created new trending store"} />
        );
      }
    });
    // delete trending store
    builder.addCase(deleteTrendingStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTrendingStore.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTrendingStores.filter(
          (item) => item._id != action.payload.storeId
        );
        toast.custom(
          <Success message={"successfully deleted trending store"} />
        );
      }
    });
    // update trending store priority
    builder.addCase(updateTrendingStorePriority.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTrendingStorePriority.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTrendingStores.filter((item) => {
          if (item._id != action.payload.trendingStore._id) {
            item.priority = action.payload.trendingStore.priority;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated trending store priority"} />
        );
      }
    });
  },
});

export const trendingStoreReducer = trendingStoreSlice.reducer;

// trending stores
// export const filterTrendingStores = ({ page, keyword }) =>
//   API.post(`/store/trending/filter?keyword=${keyword}&page=${page}`);
// export const createTrendingStore = (data) =>
//   API.post("/store/trending/create", data);
// export const changeTrendingPriority = (trendingId, data) =>
//   API.put(`/store/trending/${trendingId}/update-priority`, data);
// export const deleteTrending = (trendingId, storeName) =>
//   API.patch(`/store/trending/${trendingId}/delete`, { storeName });
// export const deleteTrendingFromStore = (storeId, storeName) =>
//   API.patch(`/store/trending/${storeId}/delete/from-stores`, { storeName });
