import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allMiddlewares: [],
  middleware: {},
  allFunctions: [],
  error: "",
};

const defaultPath = "/middleware";

export const createMiddleware = createAsyncThunk(
  "create_middleware",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllMiddlewares = createAsyncThunk(
  "fetch_middlewares",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/view?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllServerFunctions = createAsyncThunk(
  "fetch_server_functions",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/view-all-actions`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const addMiddlewareAction = createAsyncThunk(
  "add_action",
  async ({ middlewareId, features }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/add-features/${middlewareId}`,
        { features }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const removeMiddlewareAction = createAsyncThunk(
  "remove_action",
  async ({ middlewareId, features }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/remove-features/${middlewareId}`,
        { features }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateMiddleware = createAsyncThunk(
  "update_middleware",
  async ({ middlewareId, name }) => {
    try {
      const { data } = await API.put(`${defaultPath}/update/${middlewareId}`, {
        name,
      });
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const middlewareSlice = createSlice({
  name: "middleware",
  initialState,
  extraReducers: (builder) => {
    // create new middleware
    builder.addCase(createMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"created new access control"} />);
      }
    });
    // fetch all middlewares
    builder.addCase(fetchAllMiddlewares.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMiddlewares.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allMiddlewares = action.payload.allMiddlewares;
      }
    });
    // fetch all actions
    builder.addCase(fetchAllServerFunctions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllServerFunctions.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allFunctions = action.payload.allActions;
      }
    });
    // add new middleware action
    builder.addCase(addMiddlewareAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addMiddlewareAction.fulfilled, (state, action) => {
      state.loading - false;
      if (action?.payload?.success) {
        state.allMiddlewares = state.allMiddlewares.map((item)=>{
          if(item._id === action?.payload?.middleware.id){
            item.features.push( action.payload.middleware.feature)
            return item
          }
          return item
        })
        toast.custom(<Success message={"successfully added new access "} />);
      }
    });
    // remove access from an action
    builder.addCase(removeMiddlewareAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeMiddlewareAction.fulfilled, (state, action) => {
      state.loading - false;
      if (action?.payload?.success) {
        state.allMiddlewares = state.allMiddlewares.map((item)=>{
          if(item._id === action?.payload?.middleware.id){
            item.features.filter((data)=>data != action.payload.middleware.feature)
            return item
          }
          return item
        })
        toast.custom(<Success message={"successfully removed an access "} />);
      }
    });
    // update middleware details
    builder.addCase(updateMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated middleware details"} />
        );
      }
    });
  },
});

export const middlewareReducer = middlewareSlice.reducer;

