import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  admin: {
    admin: {},
    allAdmins: [],
    adminList: [],
    adminDetails: {},
    loading: false,
    error: "",
    page: 1,
    pages: 1,
    pageSize: 10,
    isAuthenticated: false,
  },
  log: {
    loading: false,
    adminLogs: [],
    error: "",
    page: 1,
    pages: 1,
    pageSize: 10,
  },
};

const defaultPath = "/admin";

export const fetchAllAdmins = createAsyncThunk(
  "fetch_all_Admins",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/get-all-admins?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllAdminsList = createAsyncThunk(
  "fetch_all_Admins_list",
  async () => {
    try {
      const url = `${defaultPath}/all-admins-list`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllAdminsLogs = createAsyncThunk(
  "fetch_all_Admins_logs",
  async ({ page = 1, pageSize = 10, search = "", admin = "" }) => {
    try {
      const url = `${defaultPath}/logs/all-logs?page=${page}&pageSize=${pageSize}&search=${search}&admin=${admin}`;
      const { data } = await API.get(url);

      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createAdmin = createAsyncThunk("create_admin", async (payload) => {
  try {
    const { data } = await API.post(`${defaultPath}/create`, payload);
    return data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const logoutAdmin = createAsyncThunk("logout_admin", async () => {
  try {
    const { data } = await API.post(`/${defaultPath}/auth/logout`);
    return data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const loginAdmin = createAsyncThunk(
  "login_admin",
  async (credentials) => {
    try {
      const { data } = await API.post(
        `${defaultPath}/auth/sign-in`,
        credentials
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateAdminRole = createAsyncThunk(
  "update_admin_role",
  async ({ adminId, role }) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-role/${adminId}`,
        { role }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAdminDetails = createAsyncThunk(
  "fetch_admin",
  async (adminId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${adminId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateAdminDetails = createAsyncThunk(
  "update_admin_details",
  async ({ adminId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${adminId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateAdminStatus = createAsyncThunk(
  "update_admin_status",
  async (adminId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/change-block-status/${adminId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateAdminPassword = createAsyncThunk(
  "update_admin_password",
  async ({ adminId, password, email }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update-password/${adminId}`,
        { password, email }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const permanentDeleteAdmin = createAsyncThunk(
  "permanent_delete_admin",
  async (adminId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${adminId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState.admin,
  extraReducers: (builder) => {
    // fetch all admins with filter and sort
    builder.addCase(fetchAllAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAdmins.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdmins = action.payload.allAdmins;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // fetch all admins for list
    builder.addCase(fetchAllAdminsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAdminsList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.adminList = action.payload.allAdmins;
      }
    });

    // create admin
    builder.addCase(createAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"new admin created successfully!"} />);
      }
    });
    // login admin
    builder.addCase(loginAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.admin = action.payload.admin;
        state.isAuthenticated = true;
        localStorage.setItem("admin", JSON.stringify(action.payload.admin));
        localStorage.setItem(
          "accessToken",
          JSON.stringify(action.payload.refreshToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(action.payload.refreshToken)
        );
        toast.custom(<Success message={"admin login successfully!"} />);
      }
    });
    // logout admin
    builder.addCase(logoutAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        localStorage.clear();
        toast.custom(<Success message={"admin logout successfully!"} />);
      }
    });
    // update admin role
    builder.addCase(updateAdminRole.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateAdminRole.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.adminList = state.adminList.map((item) => {
          if (item._id === action.payload.admin._id) {
            return {
              ...item,
              role: action.payload.admin.role,
            };
          }
          return item;
        });
        toast.custom(<Success message={"successfully updated admin role"} />);
      }
    });

    // fetch admin details
    builder.addCase(fetchAdminDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdminDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.admin = action.payload.admin;
      }
    });
    // update admin details
    builder.addCase(updateAdminDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdminDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated admin details"} />
        );
      }
    });
    // update admin status
    builder.addCase(updateAdminStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdminStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.adminList = state.allAdmins.map((item) => {
          if (item._id === action.payload.admin._id) {
            item.block = action.payload.admin.block;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated admin active status"} />
        );
      }
    });
    // update admin password
    builder.addCase(updateAdminPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdminPassword.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated admin password"} />
        );
      }
    });
    // permanent delete admin
    builder.addCase(permanentDeleteAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(permanentDeleteAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAdmins = state.allAdmins.filter(
          (item) => item._id !== action.payload.admin._id
        );
        toast.custom(
          <Success
            message={
              "successfully deleted admin. this process is not irreversible"
            }
          />
        );
      }
    });
  },
});
const adminLogSlice = createSlice({
  name: "adminLog",
  initialState: initialState.log,
  extraReducers: (builder) => {
    // fetch all admins logs
    builder.addCase(fetchAllAdminsLogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAdminsLogs.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.adminLogs = action.payload.allLogs;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
  },
});

export const adminReducer = adminSlice.reducer;
export const adminLogReducer = adminLogSlice.reducer;
