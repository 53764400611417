import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./features/sidebar";
import {
  affiliationReducer,
  categoryReducer,
  middlewareReducer,
  storeCategoryReducer,
  storeReducer,
  subCategoryReducer,
  trendingStoreReducer,
  missingCashbackReducer,
  storyReducer,
  userReducer,
  earningsReducer,
  giftCardSliderReducer,
  giftCardOderReducer,
  giftCardOfferReducer,
  giftCardReducer,
  adminReducer,
  adminLogReducer,
  offerReducer,
  termsAndPrivacyReducer,
  bannerReducer,
  QuickAccessReducer,
  trendingOfferReducer,
  storeCategoryHistoryReducer,
  ongoingSaleOfferReducer,
  termsAndPrivacyLogReducer,
  personalInterestReducer,
  testimonialReducer,
  clickReducer,
  paymentRequestReducer,
  icbGiftCardOrderReducer
} from "./features";
import { storeReviewReducer } from "./features/review.store";
import previewModalReducer from "./features/previewModal"
import referralCommissionModal from "./features/referralCommissionModal"

export const Store = configureStore({
  reducer: {
    sideBar: sidebarReducer,
    previewModal: previewModalReducer,
    referralCommissionModal,
    admin: adminReducer,
    adminLogs: adminLogReducer,
    middleware: middlewareReducer,
    store: storeReducer,
    banner: bannerReducer,
    offer: offerReducer,
    trendingOffer: trendingOfferReducer,
    trendingStore: trendingStoreReducer,
    storeCategory: storeCategoryReducer,
    storeCategoryHistory: storeCategoryHistoryReducer,
    storeReview: storeReviewReducer,
    category: categoryReducer,
    subCategory: subCategoryReducer,
    affiliation: affiliationReducer,
    missingCashback: missingCashbackReducer,
    ongoingSale: ongoingSaleOfferReducer,
    stories: storyReducer,
    user: userReducer,
    personalInterest: personalInterestReducer,
    testimonials: testimonialReducer,
    earnings: earningsReducer,
    giftCard: giftCardReducer,
    icbGiftCardOrder:icbGiftCardOrderReducer,
    giftCardSlider: giftCardSliderReducer,
    giftCardOrder: giftCardOderReducer,
    giftCardOffer: giftCardOfferReducer,
    termsAndPrivacy: termsAndPrivacyReducer,
    termsAndPrivacyLog: termsAndPrivacyLogReducer,
    quickAccess: QuickAccessReducer,
    clicks:clickReducer,
    payments:paymentRequestReducer
  },
});
