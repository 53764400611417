import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openModal: false,
    userName: null,
    userId: null,
    uid:null,
    earningId:null,
    amount:0
};

const referralCommissionModalSlice = createSlice({
    initialState,
    name: "referralCommissionModal",
    reducers: {
        openReferralCommissionModal: (state, action) => {
            state.openModal = true;
            if (action?.payload) {
                state.userName = action.payload?.userName
                state.userId = action.payload?.userId
                state.amount = action.payload?.amount
                state.uid = action.payload?.uid
                state.earningId = action.payload.earningId
            }
           
        },
        closeReferralCommissionModal: (state) => {
            state.openModal = false;
            state.userId = null
            state.userName = null
            state.uid=null
            state.amount = 0
            state.earningId = null
        }
    },
});

export const { openReferralCommissionModal, closeReferralCommissionModal } = referralCommissionModalSlice.actions;

export default referralCommissionModalSlice.reducer;
