import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  admin: {
    allBanners: [],
    bannerDetails: {},
    loading: false,
    error: "",
    page: 1,
    pages: 1,
    pageSize: 10,
  },
};

const defaultPath = "/banners/desktop";

export const fetchAllBanners = createAsyncThunk(
  "fetch_banners",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const { data } = await API.get(
        `${defaultPath}/get-all-banners?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchBannerDetails = createAsyncThunk(
  "fetch_banner_details",
  async (bannerId) => {
    try {
      const { data } = await API.post(
        `${defaultPath}/details/${bannerId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createBanner = createAsyncThunk(
  "create_banner",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateBanner = createAsyncThunk(
  "update_banner",
  async ({ bannerId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${bannerId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateBannerActiveStatus = createAsyncThunk(
  "update_banner_active_status",
  async (bannerId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-status/${bannerId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "delete_banner",
  async (bannerId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${bannerId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllBanners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllBanners.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allBanners = action.payload.allBanners;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // create
    builder.addCase(createBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBanner.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"Banner created successfully"} />
        );
      }
    });
    // get details
    builder.addCase(fetchBannerDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBannerDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.bannerDetails = action.payload.bannerDetails
      }
    });
    // update
    builder.addCase(updateBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBanner.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"desktop banner updated successfully"} />
        );
      }
    });
    // update active status
    builder.addCase(updateBannerActiveStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateBannerActiveStatus.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.allBanners = state.allBanners.map((item) => {
            if (item._id === action.payload.banner._id) {
              item.isActive = action.payload.banner.isActive;
              return item;
            }
            return item;
          });
          toast.custom(
            <Success
              message={"desktop banner updated active status successfully"}
            />
          );
        }
      }
    );
    //delete
    builder.addCase(deleteBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allBanners = state.allBanners.filter(
          (item) => item._id !== action.payload.bannerId
        );
        toast.custom(
          <Success message={"desktop banner deleted successfully"} />
        );
      }
    });
  },
});
export const bannerReducer = bannerSlice.reducer;
