import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allOngoingSales: [],
  ongoingSaleDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/offer/on-going-sales";

export const fetchAllOngoingSaleOffers = createAsyncThunk(
  "fetch_all_ongoing_sales_offers",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/all-ongoing-sales?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchOngoingSaleDetails = createAsyncThunk(
  "fetch_ongoing_sale_details",
  async (saleId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${saleId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createOngoingSale = createAsyncThunk(
  "create_ongoing_sales",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateOngoingSale = createAsyncThunk(
  "update_ongoing_sales",
  async ({ saleId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${saleId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteOngoingSale = createAsyncThunk(
  "delete_ongoing_sales",
  async (saleId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${saleId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const changeActiveStatus = createAsyncThunk(
  "change_status",
  async (saleId) => {
    try {
      const { data } = await API.put(`${defaultPath}/change-status/${saleId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const ongoingSaleOfferSlice = createSlice({
  name: "ongoingSaleOffer",
  initialState,
  extraReducers: (builder) => {
    // fetch all data
    builder.addCase(fetchAllOngoingSaleOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllOngoingSaleOffers.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOngoingSales = action.payload.allOngoingSales;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // fetch ongoing sale details
    builder.addCase(fetchOngoingSaleDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOngoingSaleDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.ongoingSaleDetails = action.payload.onGoingSaleDetails;
      }
    });
    // update ongoing sale details
    builder.addCase(updateOngoingSale.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOngoingSale.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success
            message={"successfully updated updated ongoing sale details"}
          />
        );
      }
    });
    // permanently deleted ongoing sale
    builder.addCase(deleteOngoingSale.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOngoingSale.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOngoingSales = state.allOngoingSales.filter(
          (item) => item._id != action.payload.onGoingSaleId
        );
        toast.custom(
          <Success message={"successfully deleted ongoing sale offer!"} />
        );
      }
    });
    // create new on going sale
    builder.addCase(createOngoingSale.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOngoingSale.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully created new ongoing sale!"} />
        );
      }
    });
    // create new trending store
    builder.addCase(changeActiveStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeActiveStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOngoingSales = state.allOngoingSales.map((item) =>
          item._id === action.payload.onGoingSaleId
            ? { ...item, active: !item.active }
            : item
        );

        toast.custom(<Success message={"successfully changed the status"} />);
      }
    });
  },
});

export const ongoingSaleOfferReducer = ongoingSaleOfferSlice.reducer;
