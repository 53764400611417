import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  admin: {
    allQuickAccess: [],
    quickAccessDetails: {},
    loading: false,
    error: "",
    page: 1,
    pages: 1,
    pageSize: 10,
  },
};

const defaultPath = "/quick-access";

export const fetchAllQuickAccess = createAsyncThunk(
  "fetch_quick_access",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const { data } = await API.get(
        `${defaultPath}/get-all-quick-access?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createQuickAccess = createAsyncThunk(
  "create_quick_access",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchQuickAccessDetails = createAsyncThunk(
  "fetch_quick_access_details",
  async (id) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateQuickAccess = createAsyncThunk(
  "update_quick_access",
  async ({ quickAccessId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${quickAccessId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateQuickAccessActiveStatus = createAsyncThunk(
  "update_quick_access_active_status",
  async (quickAccessId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-status/${quickAccessId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteQuickAccess = createAsyncThunk(
  "delete_quick_access",
  async (quickAccessId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${quickAccessId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const quickAccessSlice = createSlice({
  name: "quickAccess",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllQuickAccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllQuickAccess.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allQuickAccess = action.payload.allQuickAccess;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    builder.addCase(fetchQuickAccessDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchQuickAccessDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.quickAccessDetails = action.payload.quickAccessDetails;

      }
    });
    // create
    builder.addCase(createQuickAccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createQuickAccess.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={" quick access created successfully"} />
        );
      }
    });
    // update
    builder.addCase(updateQuickAccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuickAccess.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"quick access updated successfully"} />
        );
      }
    });
    // update active status
    builder.addCase(updateQuickAccessActiveStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateQuickAccessActiveStatus.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.allQuickAccess = state.allQuickAccess.map((item) => {
            if (item._id === action.payload.quickAccess._id) {
              item.isActive = action.payload.quickAccess.isActive;
              return item;
            }
            return item;
          });
          toast.custom(
            <Success
              message={"quick access updated active status successfully"}
            />
          );
        }
      }
    );
    //delete
    builder.addCase(deleteQuickAccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuickAccess.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allQuickAccess = state.allQuickAccess.filter(
          (item) => item._id !== action.payload.quickAccessId
        );
        toast.custom(
          <Success message={"quick access  deleted successfully"} />
        );
      }
    });
  },
});
export const QuickAccessReducer = quickAccessSlice.reducer;
