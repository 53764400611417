import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allGiftCardSliders: [],
  giftCardSliderDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/giftcards/sliders";

export const fetchAllGiftCardSliders = createAsyncThunk(
  "fetch_giftCard_sliders",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/all-sliders?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createGiftCardSlider = createAsyncThunk(
  "create_giftCard_slider",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateGiftCardSlider = createAsyncThunk(
  "update_giftCard_slider",
  async ({ sliderId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${sliderId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteGiftCardSlider = createAsyncThunk(
  "delete_giftCard_slider",
  async (sliderId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${sliderId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchGiftCardSliderDetails = createAsyncThunk(
  "fetch_giftCard_slider_details",
  async (sliderId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${sliderId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateGiftCardSliderPriority = createAsyncThunk(
  "update_giftCard_priority",
  async ({ sliderId, priority }) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-priority/${sliderId}`,
        {
          priority,
        }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const giftCardSliderSlice = createSlice({
  name: "giftCardSliders",
  initialState,
  extraReducers: (builder) => {
    // fetch all giftCard sliders
    builder.addCase(fetchAllGiftCardSliders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllGiftCardSliders.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardSliders = action.payload.allGiftCardSliders;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // create gift card slider
    builder.addCase(createGiftCardSlider.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createGiftCardSlider.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"new giftcard slider created successfully"} />);
      }
    });
    // update  gift card slider priority
    builder.addCase(updateGiftCardSliderPriority.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGiftCardSliderPriority.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardSliders = state.allGiftCardSliders.map((item) => {
          if (item._id === action.payload.giftCardSlider._id) {
            item.priority = action.payload.giftCardSlider.priority
            return item
          }
          return item
        })
        toast.custom(<Success message={"giftcard slider priority updated successfully"} />);
      }
    });

    // delete  gift card slider
    builder.addCase(deleteGiftCardSlider.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGiftCardSlider.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardSliders = state.allGiftCardSliders.map((item) => {
          if (item._id === action.payload.giftCardSlider._id) {
            item.active = action.payload.giftCardSlider.active
            return item
          }
          return item
        })
        toast.custom(<Success message={"successfully updated giftcard slider active status"} />);
      }
    });
    // fetch  gift card slider details
    builder.addCase(fetchGiftCardSliderDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGiftCardSliderDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.giftCardSliderDetails = action.payload.giftCardSlider;
      }
    });
    // update  gift card slider
    builder.addCase(updateGiftCardSlider.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGiftCardSlider.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"giftcard slider details updated successfully"} />);
      }
    });
  },
});

export const giftCardSliderReducer = giftCardSliderSlice.reducer;
