import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allStories: [],
  storyDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
  error: "",
};

const defaultPath = "/banners/stories";

export const fetchAllStories = createAsyncThunk(
  "fetch_stories",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/get-all-stories?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createStories = createAsyncThunk(
  "create_story",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateStoriesActiveStatus = createAsyncThunk(
  "update_stories_Active_status",
  async (id) => {
    try {
      const { data } = await API.patch(`${defaultPath}/update-status/${id}`,);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);


export const fetchStoryDetails = createAsyncThunk(
  "stories_details",
  async (storyId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${storyId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateStory = createAsyncThunk(
  "update_story",
  async ({ storyId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${storyId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteStory = createAsyncThunk(
  "delete_story",
  async (storyId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${storyId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const storySlice = createSlice({
  name: "stories",
  initialState,
  extraReducers: (builder) => {
    // fetch all stories
    builder.addCase(fetchAllStories.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStories.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStories = action.payload.allMobileStories;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // create stories 
    builder.addCase(createStories.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createStories.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"new story created successfully"} />);
      }
    });
    // fetch  stories details
    builder.addCase(fetchStoryDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchStoryDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.storyDetails = action.payload.storyDetails;
      }
    });
    // update  stories updateStoriesActiveStatus
    builder.addCase(updateStory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateStory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"story details updated successfully !"} />);
      }
    });
    // update active status
    builder.addCase(updateStoriesActiveStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateStoriesActiveStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStories = state.allStories.map((item) => {
          if (item._id === action.payload.story._id) {
            item.isActive = action.payload.story.isActive
            return item
          }
          return item
        })
        toast.custom(<Success message={"story active status updated successfully!"} />);
      }
    });
    // delete
    builder.addCase(deleteStory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteStory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStories = state.allStories.map((item) => item._id !== action.payload.storyId)
        toast.custom(<Success message={"story active status updated successfully!"} />);
      }
    });
  },
});

export const storyReducer = storySlice.reducer;
