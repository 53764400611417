import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allTermsAndPrivacies: [],
  termsAndPrivacyDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};

const logInitialState = {
  loading: false,
  allTermsAndPrivacyLogs: [],
  page: 1,
  pages: 1,
  pageSize: 10,
};

const defaultPath = "/terms-and-privacy";

export const fetchAllTermsAndPrivacies = createAsyncThunk(
  "fetch_all_terms_and_privacies",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/all-terms-and-privacies?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllTermsAndPrivacyLogs = createAsyncThunk(
  "fetch_all_terms_and_privacy_logs",
  async ({ page = 1, pageSize = 10, search = "", admin = "" }) => {
    try {
      const url = `${defaultPath}/logs?page=${page}&pageSize=${pageSize}&search=${search}&admin=${admin}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchTermsAndPrivacyDetails = createAsyncThunk(
  "fetch_terms_and_privacy_details",
  async (id) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateTermsAndPrivacy = createAsyncThunk(
  "update_terms_and_privacy",
  async ({ payload, id }) => {
    try {
      const { data } = await API.put(`${defaultPath}/update/${id}`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateTermsAndPrivacyType = createAsyncThunk(
  "update_terms_and_privacy_type",
  async (id, type) => {
    try {
      const { data } = await API.put(`${defaultPath}/update-type/${id}`, { type });
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateTermsAndPrivacyStatus = createAsyncThunk(
  "update_terms_and_privacy_status",
  async (id) => {
    try {
      const { data } = await API.patch(`${defaultPath}/update-status/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createTermsAndPrivacy = createAsyncThunk(
  "create_terms_and_privacy",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const termsAndPrivacySlice = createSlice({
  name: "termsAndPrivacy",
  initialState,
  extraReducers: (builder) => {
    // fetch all terms and privacy
    builder.addCase(fetchAllTermsAndPrivacies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTermsAndPrivacies.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTermsAndPrivacies = action.payload.allTermsAndPrivacies;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // fetch terms and privacy details
    builder.addCase(fetchTermsAndPrivacyDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchTermsAndPrivacyDetails.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.termsAndPrivacyDetails =
            action.payload.termsAndPrivacy;
        }
      }
    );
    // update  terms and privacy
    builder.addCase(updateTermsAndPrivacy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTermsAndPrivacy.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated terms and privacy"} />
        );
      }
    });
    // update terms and privacy type
    builder.addCase(updateTermsAndPrivacyType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateTermsAndPrivacyType.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.allTermsAndPrivacies = state.allTermsAndPrivacies.map(
            (item) => {
              if (item._id === action.payload.termsAndPrivacy._id) {
                item.type = action.payload.termsAndPrivacy.type;
                return item;
              }
              return item;
            }
          );
          toast.custom(
            <Success message={"successfully updated terms and privacy type"} />
          );
        }
      }
    );
    // create new terms and privacy
    builder.addCase(createTermsAndPrivacy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTermsAndPrivacy.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully created new terms and privacy"} />
        );
      }
    });
    // update is active stats of terms and privacy
    builder.addCase(updateTermsAndPrivacyStatus.pending, (state,) => {
      state.loading = true;
    });
    builder.addCase(updateTermsAndPrivacyStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTermsAndPrivacies = state.allTermsAndPrivacies.map(
          (item) => {
            if (item._id === action.payload.termsAndPrivacyId) {
              item.active = !item.active;
              return item;
            }
            return item;
          }
        );
        toast.custom(<Success message={"successfully updated terms and privacy status"} />)
      }
    });
  },
});

const termsAndPrivacyLogSlice = createSlice({
  name: "termsAndPrivacyLogs",
  initialState: logInitialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllTermsAndPrivacyLogs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllTermsAndPrivacyLogs.fulfilled, (state, action) => {
      state.loading = false
      if (action?.payload?.success) {
        state.allTermsAndPrivacyLogs = action.payload.allTermsAndPrivacyLogs;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    })

  }
})

export const termsAndPrivacyLogReducer = termsAndPrivacyLogSlice.reducer;
export const termsAndPrivacyReducer = termsAndPrivacySlice.reducer;
