import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allCategory: [],
  allCategoryList: [],
  categoryDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/categories";
export const fetchAllCategories = createAsyncThunk(
  "fetch_all_category",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/all-categories?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllCategoriesList = createAsyncThunk(
  "fetch_all_category_list",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/all-categories-list`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createCategory = createAsyncThunk(
  "create_category",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateCategory = createAsyncThunk(
  "update_category",
  async ({ categoryId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${categoryId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchCategoryDetails = createAsyncThunk(
  "fetch_category_details",
  async (categoryId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${categoryId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateCategoryTrendingStatus = createAsyncThunk(
  "fetch_category_trending_status",
  async (categoryId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update/trending-status/${categoryId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateCategoryTrendingPriority = createAsyncThunk(
  "fetch_category_trending_priority",
  async ({ categoryId, priority = 0 }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/trending-priority/${categoryId}`,
        { priority }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "delete_category",
  async (categoryId) => {
    try {
      const { data } = await API.patch(`${defaultPath}/delete/${categoryId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    // fetch all categories
    builder.addCase(fetchAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allCategory = action.payload.allCategories;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // fetch all categories list
    builder.addCase(fetchAllCategoriesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCategoriesList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        console.log(action.payload);
        state.allCategoryList = action.payload.allCategories;
      }
    });
    // create new category
    builder.addCase(createCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"create new category successfully"} />);
      }
    });

    // fetch category details
    builder.addCase(fetchCategoryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategoryDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.categoryDetails = action.payload.category;
      }
    });
    // update category
    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={" category updated successfully"} />);
      }
    });
    // update category trending status
    builder.addCase(updateCategoryTrendingStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategoryTrendingStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allCategory.map((item) => {
          if (item._id === action.payload.category._id) {
            item.trending = action.payload.category.trending;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success
            message={"successfully updated trending status of category  "}
          />
        );
      }
    });
    // update category trending priority
    builder.addCase(updateCategoryTrendingPriority.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateCategoryTrendingPriority.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.allCategory.map((item) => {
            if (item._id === action.payload.category._id) {
              item.trendingPriority = action.payload.category.trendingPriority;
              return item;
            }
            return item;
          });
          toast.custom(
            <Success
              message={"successfully updated trending priority of category  "}
            />
          );
        }
      }
    );

    // update category
    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allCategory = state.allCategory.filter(
          (item) => item._id != action.payload.category._id
        );

        toast.custom(
          <Success
            message={`category deleted
               successfully`}
          />
        );
      }
    });
  },
});

export const categoryReducer = categorySlice.reducer;

// export const getAllCategories = () =>
//   API.post("/categories/get-all-categories");
// export const categoryDeleteStatus = (categoryId) =>
//   API.put(`/categories/change-delete/${categoryId}`);
// export const createCategory = (categoryData) =>
//   API.post("/categories/create", categoryData);
// export const searchCategories = (keyword, page) =>
//   API.post(`/categories/filter/search?page=${page}&keyword=${keyword}`);
// export const updateCategory = (categoryId, title) =>
//   API.put(`/categories/update/${categoryId}`, { title });
