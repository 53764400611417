import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allGiftCardOrders: [],
  giftCardDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/giftcards/orders";

export const fetchAllGiftCardOrders = createAsyncThunk(
  "fetch_giftCard_orders",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    name = "",
    status = "",
    expiry = "",
  }) => {
    try {
      const url = `${defaultPath}/all-orders?page=${page}&pageSize=${pageSize}&search=${search}&name=${name}&status=${status}&expiry=${expiry}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createGiftCardOrder = createAsyncThunk(
  "create_giftCard_order",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateGiftCardOrder = createAsyncThunk(
  "update_giftCard_order",
  async ({ sliderId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${sliderId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteGiftCardOrder = createAsyncThunk(
  "delete_giftCard_Order",
  async (sliderId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${sliderId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchGiftCardOrderDetails = createAsyncThunk(
  "fetch_giftCard_order_details",
  async (sliderId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${sliderId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const giftCardOrderSlice = createSlice({
  name: "giftCardOrders",
  initialState,
  extraReducers: (builder) => {
    // fetch all giftCard orders
    builder.addCase(fetchAllGiftCardOrders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllGiftCardOrders.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allGiftCardOrders = action.payload.allGiftCardOrders;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // create gift card slider
    builder.addCase(createGiftCardOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createGiftCardOrder.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={""} />);
      }
    });
    // delete  gift card slider
    builder.addCase(deleteGiftCardOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGiftCardOrder.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={""} />);
      }
    });
    // fetch  gift card slider details
    builder.addCase(fetchGiftCardOrderDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGiftCardOrderDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.storyDetails = action.payload.storyDetails;
      }
    });
    // update  gift card slider
    builder.addCase(updateGiftCardOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGiftCardOrder.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={""} />);
      }
    });
  },
});

export const giftCardOderReducer = giftCardOrderSlice.reducer;
